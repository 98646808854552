html.headroom--top body[data-template="home"]{
    &:not(.navigation-open){
        .page__header .search {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 250px; 
            width: 100%;
            padding: 0 20px;
            max-width: 560px;
            .search__preview{
                width: 100%;
            }
        }
    }
    
}
.c-gufi-preview-wrapper-active{
    overflow-y: hidden;
}
body[data-template="home"]{
    .card{
        border-radius: 0!important;
    }
}