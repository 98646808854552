body{
    .splide:not(.splide--custom) .splide__pagination button{
        &.is-active{
            color: $color__primary;
        }
        @include hover-focus-visible{
            color: $color__primary--hover;
        }
    }
}
