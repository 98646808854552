.content-navigation{
    .page__main &{
        &__item{
            background-color: $color__primary;
            color: $color__white;
            border-radius: 0;
            @include transition;
            @include hover-focus-visible{
                .content-navigation__body{
                    background-color: $color__primary--hover;
                    h3 a{
                        text-decoration: none;
                    }
                }
            }
        }
        &__body{
            background-color: $color__primary;
            color: $color__white;
            border-radius: 0;
            a.stretched-link{
                color: $color__white;
            }
        }
        .content-navigation__item p[aria-hidden=true] img{
            @include recolor($color__white);
        }
    }

}

