body .off-canvas__toggle{
    html.js &{
        border-radius: 60px;
        height: 90px;
        width: 90px;
        top: 15px;
        right: 25px;

        @include media-breakpoint-down(lg) {
            border-radius: 60px;
            height: 60px;
            width: 60px;
            top: 15px;
        }
    }

    html.js.headroom--unpinned &{
        top: 5px;
        @include media-breakpoint-down(lg) {
            top: 15px;
        }
    }
}
body .off-canvas__trigger:checked+.off-canvas__wrapper .off-canvas__toggle--off{
    border-radius: 0;
}
